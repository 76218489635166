<template>
  <div class="my-select">
    <!--<el-cascader-->
    <!--    ref="refSubCat"-->
    <!--    v-model="state"-->
    <!--    :props="propsFn()"-->
    <!--    :placeholder="ph"-->
    <!--    @change="handleChangeCode"-->
    <!--/>-->
    <el-autocomplete
        v-model="state"
        class="ipt"
        :value-key="state && !state.includes('/') ? 'nikeName' : 'name'"
        :fetch-suggestions="querySearchAsync"
        @select="handleSelect"
        :placeholder="ph"
        trigger-on-focus
        @clear="clear"
        popper-class="select-option"
    >
      <p class="icon" v-show="show" slot="prefix" style="color: #000;line-height: 50px;font-size: 18px">尋根|大陸祖地</p>
    </el-autocomplete>
  </div>
</template>

<script>
import {getTraditionalArea} from "../api/data";
import {debounce} from "../utils/tool";

export default {
  props: {
    ph: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      state: this.value,
    };
  },
  watch: {
    state() {
      this.$emit("input", this.state);
    }
  },
  methods: {
    propsFn() {
      return {
        lazy: true,
        value: "code",
        label: "name",
        checkStrictly: true,
        async lazyLoad(node, resolve) {
          const {level} = node;
          if (level === 0) {
            const res = await getTraditionalArea({
              PageIndex: 1,
              PageSize: 100,
            });
            resolve(res.items);
          } else {
            const res = await getTraditionalArea({
              PageIndex: 1,
              PageSize: 100,
              ParentCode: node.data.code,
            });
            resolve(res.items);
          }
        },
      };
    },
    clear() {
      document.activeElement.blur();
    },
    reset() {
      this.$emit("input", "");
    },
    handleChangeCode() {
      const node = this.$refs["refSubCat"].getCheckedNodes()[0];
      if (node.data.rootLevelName) {
        this.state = node.data.rootLevelName + "/" + node.label;
      } else {
        this.state = node.label;
      }
      this.$nextTick(() => {
        const dom = document.getElementsByClassName("el-radio is-checked");
        const radioDom = dom[dom.length - 1];
        const brother = radioDom?.nextElementSibling;
        brother && brother.click();
      });
      //   this.getAreaList();
      //   this.$emit("getData", this.code[this.code.length - 1]);
      //   this.$emit("setData", this.state);
      this.$emit("input", this.state);
    },
    handleSelect(e) {
      if (e.rootLevelName) {
        this.state = e.rootLevelName + "/" + e.name;
      } else {
        this.state = e.name;
      }
      //   this.$emit("getData", this.code[this.code.length - 1]);
      //   this.$emit("setData", this.state);
      this.$emit("input", this.state);
    },
    querySearchAsync: debounce(
        async function (e, cb) {
          const res = await getTraditionalArea({
            PageIndex: 1,
            PageSize: 100,
            Name: e,
          });
          if (this.state && !this.state.includes("/")) {
            if (this.state === "台湾省" || this.state === "福建省") {
              res.items.forEach((element) => {
                element.nikeName = element.name;
              });
            } else {
              res.items.forEach((element) => {
                element.nikeName =
                    element.name + "(" + element.rootLevelName + ")";
              });
            }
          }
          const data = res.items.sort((a, b) => {
            //福建
            if (a.code === "4535") return -1;
            if (b.code === "4535") return 1;
            return 0; // 其他情况保持原顺序
          });
          cb(data);
        },
        500,
        true
    ),
  },
};
</script>

<style lang="scss" scoped>
.my-select {
  //position: relative;
  //.ipt {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 85%;
  //  ::v-deep .el-input {
  //    .el-input__inner {
  //      border-right: none;
  //      border-radius: 0;
  //    }
  //
  //    .el-input__inner:focus {
  //      border-color: #dcdfe6;
  //    }
  //    .el-input__inner:hover {
  //      border-color: #dcdfe6;
  //    }
  //  }
  //}

  .select-option {
    overflow: auto;
  }

  ::v-deep .el-cascader {
    width: 100%;

    .el-input__inner {
      border-radius: 0;
    }

    .el-input.is-focus .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }

  ::v-deep .el-cascader:not(.is-disabled):hover .el-input__inner {
    border-color: #dcdfe6;
  }
}
</style>

