/**
 * 防抖
 * @param {*} fn 需要防抖的方法
 * @param {*} delay 防抖时间
 * @param {*} atOnce 是否需要立即执行
 * @returns
 */
export const debounce = function(fn, time, atOnce) {
    const delay = time || 200
    let timer = null
    let count = 0
    return function() {
        const _this = this
        const args = arguments
        // 如果是立即执行
        if (atOnce) {
            // 第一次直接执行不用等
            if (count === 0) {
                fn.apply(_this, args)
                count++
            } else {
                if (timer) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function() {
                    fn.apply(_this, args)
                }, delay)
            }
            //
        } else {
            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(function() {
                fn.apply(_this, args)
            }, delay)
        }
    }
}

