<template>
  <div class="page">
    <div class="home-container">
      <div
        class="left"
        ref="left"
        :style="{ width: isShowLeft ? '45%' : '0%' }"
      >
        <template v-if="isShowLeft">
          <div class="top">
            <div class="title" style="margin-bottom: 8px">
              <span>大陆</span>
              →
              <span>台湾</span>
            </div>
            <div
              class="content"
              style="border: 1px solid #eee; padding: 8px; width: 100%"
            >
              <el-form ref="form" label-width="80px" class="form-class">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="迁台姓氏:">
                      <el-input
                        v-model="Surname"
                        clearable
                        placeholder="请输入迁台姓氏"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="迁台始祖:">
                      <el-input
                        v-model="RelatedPersons"
                        clearable
                        placeholder="请输入迁台始祖"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="迁徙时间:">
                      <el-input
                        v-model="BeginYear"
                        clearable
                        placeholder="请输入时间"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="至">
                      <el-input
                        v-model="EndYear"
                        clearable
                        placeholder="请输入时间"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="迁出地:">
                      <my-select v-model="areaName" ph="请选择迁出地" :show="false"/>
                      <!--<div-->
                      <!--  @click="showPanel('startPointId')"-->
                      <!--  style="height: 40px;"-->
                      <!--&gt;-->
                      <!--  <el-input-->
                      <!--    id="startInput"-->
                      <!--    v-model="areaName"-->
                      <!--    clearable-->
                      <!--    placeholder="请选择迁出地"-->
                      <!--  ></el-input>-->
                      <!--  <el-cascader-->
                      <!--    id="startPointId"-->
                      <!--    v-model="areaName"-->
                      <!--    :options="areaList"-->
                      <!--    :props="props"-->
                      <!--    clearable-->
                      <!--    filterable-->
                      <!--    placeholder="请选择迁出地"-->
                      <!--    @change="cityChange"-->
                      <!--  >-->
                      <!--    <template slot-scope="{ data }">-->
                      <!--<span-->
                      <!--  style="width: 100%; display: inline-block"-->
                      <!--  @click="GetNodes(data.code, data)"-->
                      <!--&gt;{{ data.name }}</span-->
                      <!--&gt;-->
                      <!--    </template>-->
                      <!--  </el-cascader>-->
                      <!--</div>-->
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="迁入地:">
                      <my-select v-model="areaName2" ph="请选择迁出地" :show="false"/>
                      <!--<div-->
                      <!--  @click="showPanel('endPointId')"-->
                      <!--  style="height: 40px;"-->
                      <!--&gt;-->
                      <!--  <el-input-->
                      <!--    id="endInput"-->
                      <!--    v-model="areaName2"-->
                      <!--    clearable-->
                      <!--    placeholder="请选择迁入地"-->
                      <!--  ></el-input>-->

                      <!--  <el-cascader-->
                      <!--    id="endPointId"-->
                      <!--    v-model="areaName2"-->
                      <!--    :options="areaList"-->
                      <!--    :props="props"-->
                      <!--    clearable-->
                      <!--    filterable-->
                      <!--    placeholder="请选择迁入地"-->
                      <!--    @change="cityChange1"-->
                      <!--  >-->
                      <!--    <template slot-scope="{ data }">-->
                      <!--<span-->
                      <!--  style="width: 100%; display: inline-block"-->
                      <!--  @click="GetNodes(data.code, data)"-->
                      <!--&gt;{{ data.name }}</span-->
                      <!--&gt;-->
                      <!--    </template>-->
                      <!--  </el-cascader>-->
                      <!--</div>-->
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item style="margin-bottom: 0px" label="关键词:">
                      <div
                        class="info"
                        style="display: flex; align-items: center; margin-bottom: 10px"
                      >
                        <el-input
                          v-model="MigrateText"
                          clearable
                          placeholder="请输入关键词"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <div class="btn">
                <button
                  style="
                    height: 30px;
                    line-height: 30px;
                    width: 100px;
                    background-color: rgb(82, 142, 197);
                    color: #fff;
                    border-radius: 10px;
                    cursor: pointer;
                    border: none;
                  "
                  @click="handleSearch"
                >
                  查询
                </button>
              </div>
            </div>
          </div>

          <div
            v-show="total"
            class="search-result"
            style="border: 1px solid #eee; padding: 10px; overflow: auto;border-radius: 10px;margin-top: 8px"
          >
            <div class="content" style="margin-top: 20px">
              <span
                class="tile"
                style="
                  background-color: rgb(126, 206, 244);
                  display: inline-block;
                  padding: 7px;
                  width: 100px;
                  border-radius: 2px;
                "
              >
                查 询 结 果
              </span>
              <ul class="list" style="margin-top: 15px">
                <li
                  v-for="v in migrateList"
                  :key="v.id"
                  class="item"
                  style="border-bottom: 2px solid #000; padding: 10px 0"
                  :class="{ current: currentRow.id === v.id }"
                >
                  <p class="name" style="margin-bottom: 10px; cursor: pointer">
                    <!-- @click="getDetails(v.bookID)" -->
                    <strong>名称：</strong>
                    <strong>{{ v.title }}</strong>
                  </p>
                  <p style="margin-bottom: 10px">迁徙记录：</p>
                  <p style="margin-bottom: 10px; line-height: 20px">
                    <i style="font-style: normal" v-show="v.personalName">{{ v.personalName }}：</i>
                    <span v-if="v.migrateDate || v.migrateDateYear"
                      >{{ v.migrateDate }}({{ v.migrateDateYear }})，</span
                    >
                    <span>{{ v.beginPlace.replace("#", "") }}</span>
                    &nbsp;
                    <img
                      style="width: 18px; vertical-align: middle"
                      src="../../assets//image/前往.png"
                    />&nbsp;
                    <span>{{ v.endPlace.replace("#", "") }}</span>
                  </p>
                  <p
                    style="margin-bottom: 10px; cursor: pointer"
                    @click="handleCurrentChange(v)"
                  >
                    <span
                      style="
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        line-height: 20px;
                      "
                      >迁徙信息：{{ v.sourceText }}</span
                    >
                  </p>
                  <div>
                    <button
                      style="
                        background-color: rgb(136, 171, 208);
                        border: none;
                        color: #fff;
                        padding: 5px;
                        cursor: pointer;
                        border-radius: 2px;
                      "
                      @click="viewInfo(v)"
                    >
                      查看详细信息
                    </button>
                    <button
                      style="
                        margin-left: 20px;
                        background-color: rgb(136, 171, 208);
                        border: none;
                        color: #fff;
                        padding: 5px;
                        cursor: pointer;
                        border-radius: 2px;
                      "
                      @click="viewPic(v.pictureFullName, v.genealogyOCRInfoID)"
                    >
                      查看原文摘录
                    </button>
                  </div>
                </li>
              </ul>
            </div>

            <el-pagination
              style="margin-top: 10px"
              v-if="total && paginationShow"
              @current-change="onCurrentChange"
              @size-change="handleSizeChange"
              :page-sizes="[10, 20, 30, 40, 50]"
              :page-size="PageSize"
              layout="total, sizes, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </template>

        <img
          v-show="isShowLeft"
          ref="zkimg"
          @click="onShowLeft"
          src="../../assets/image/折叠.png"
          class="img"
          title="关闭"
        />
      </div>

      <div class="right">
        <img
          v-show="!isShowLeft"
          ref="zkimg1"
          @click="onShowLeft"
          src="../../assets/image/折叠.png"
          class="zkimg"
          title="展开"
        />
        <arcMap
          :allPointsList="allPointsList"
          :migrationPointsList="migrationPointsList"
          ref="bmap"
        />
        <!-- <bmap
          :allPointsList="allPointsList"
          :migrationPointsList="migrationPointsList"
          ref="bmap"
        /> -->
        <div class="info" v-if="details?.length">
          <div class="item" v-for="v in details" :key="v.id">
            <div>
              <span>{{ v.personalName }}</span
              >，<span
                >{{ v.migrateDate
                }}<span v-if="v.migrateDate"
                  >({{ v.migrateDateYear }})</span
                ></span
              >：{{ v.beginPlace.replace("#", "") }}
              <img class="img" src="../../assets//image/右箭头(1).png" />
              {{ v.endPlace.replace("#", "") }}
            </div>
          </div>
        </div>

        <!--
          <div class="time-line">
            <time-line
              :dynastyData="dynastyData"
              @reRequest="reRequest"
            ></time-line>
          </div>
        -->

        <div class="bottom">福建两岸信息技术有限公司</div>
      </div>
    </div>

    <el-dialog
      id="dlog"
      :visible.sync="dialogTableVisible"
      style="height: 90vh"
      @close="onClose"
    >
      <div
        style="
          height: 60vh;
          display: flex;
          overflow: hidden;
          justify-content: center;
        "
        v-dragscroll
      >
        <el-image
          v-resize
          @load="onload"
          @error="onerror"
          :src="`/api/fileinfo/image/GetOriImage?filename=${pictureFullName}&ocrSource=1`"
          style="
             {
              height: 100%;
              transform-origin: center top;
            }
          "
          fit="cover"
        />
      </div>
      <div
        class="el-dialog__footer"
        style="display: flex; justify-content: center; align-items: center"
      >
        <el-button @click="Before">上一页</el-button>
        <el-button @click="After">下一页</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="infoShow"
      @close="infoShow = false"
      title="详细信息"
    >
      <p style="margin: 10px 0; font-size: 18px">
        <strong>名称：</strong><strong>{{ infoObj.title }}</strong>
      </p>
      <div
        style="
          font-size: 18px;
          line-height: 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <p style="padding: 0 30px 0 0" v-if="infoObj.publisher">
          <span>编者：</span>
          <span>{{ infoObj.publisher }}</span>
        </p>
        <p style="padding: 0 30px 0 0" v-if="infoObj.dateOfPublication">
          <span>出版日期：</span>
          <span>{{ infoObj.dateOfPublication }}</span>
        </p>
        <p style="padding: 0 30px 0 0" v-if="infoObj.copies">
          <span>卷册数：</span>
          <span>{{ infoObj.copies }}</span>
        </p>
        <p style="padding: 0 30px 0 0" v-if="infoObj.ancestor">
          <span>始祖：</span>
          <span>{{ infoObj.ancestor }}</span>
        </p>
        <p style="padding: 0 30px 0 0" v-if="infoObj.owner">
          <span>收藏单位：</span>
          <span>{{ infoObj.owner }}</span>
        </p>
      </div>
      <div style="margin: 10px 0; font-size: 18px">
        <p style="margin-bottom: 10px">迁徙记录：</p>
        <p style="margin-bottom: 10px; line-height: 20px">
          <i style="font-style: normal">{{ infoMrigObj.personalName }}：</i>
          <span
            infoMrigObj-if="infoMrigObj.migrateDate || infoMrigObj.migrateDateYear"
            >{{ infoMrigObj.migrateDate }}({{
              infoMrigObj.migrateDateYear
            }})，</span
          >
          <span>{{ infoMrigObj.beginPlace.replace("#", "") }}</span>
          &nbsp;
          <img
            style="width: 18px; vertical-align: middle"
            src="../../assets//image/前往.png"
          />&nbsp;
          <span>{{ infoMrigObj.endPlace.replace("#", "") }}</span>
        </p>
        <p style="margin-bottom: 10px">
          <span style="line-height: 25px"
            >迁徙信息：{{ infoMrigObj.sourceText }}</span
          >
        </p>
      </div>

      <!-- <div class="el-dialog__footer">
        <el-button @click="infoShow = false">关 闭</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import {
  getProvincialList,
  getCityList,
  getDistrictList,
  getTownList,
  getVillageList,
} from "@/api/region";
import { getMigrate, BeforeOrNext, getDetails } from "@/api/susername";

import bmap from "../../components/bmap/bmap.vue";
import arcMap from "../../components/arcgisMap";
import MySelect from "../../components/MySelect.vue";
// import timeLine from "../../components/timeline";
export default {
  components: {MySelect, bmap, arcMap },
  name: "HomePage",
  data() {
    return {
      dynastyData: [
        { name: "唐", id: 1 },
        { name: "宋", id: 2 },
        { name: "明", id: 4 },
        { name: "清", id: 5 },
        { name: "民国", id: 6 },
        { name: "至今", id: 7 },
      ],
      MigrateDynasty: "",
      infoMrigObj: {
        endPlace: "",
        beginPlace: "",
      },
      infoObj: {},
      infoShow: false,
      oldMigrateList: [],
      paginationShow: true,
      bookId: "",
      isShowLeft: true,
      MigrateText: "",
      inputValue: "", // 手动输入的值
      loadingInstance: "",
      genealogyOCRInfoID: "",
      pictureFullName: "",
      dialogTableVisible: false,
      currentRow: {},
      time: "",
      Surname: "",
      EndYear: "",
      BeginYear: "",
      RelatedPersons: "",
      CountryList: [],
      areaName: "",
      areaName2: "",
      PageIndex: 1,
      PageSize: 10,
      total: 0,
      props: {
        checkStrictly: true,
        // expandTrigger: "hover",
        label: "name",
        value: "name",
      },
      migrateList: [],
      routeList: [],
      allPointsList: {},
      migrationPointsList: [],
      singleDisplay: [],
      options: {
        fullscreen: true,
        lock: true,
        text: "Loading",
        target: ".el-dialog__body",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },
      details: [],
    };
  },
  created() {
    this.getProvincialList();
  },
  computed: {
    //地区
    areaList() {
      return this.CountryList;
    },
  },
  methods: {
    // reRequest(e) {
    //   this.pageIndex = 1;
    //   this.MigrateDynasty = e;
    //   this.onSearch();
    // },
    async viewInfo(v) {
      this.infoMrigObj = v;
      this.infoObj = {
        title: v.title,
        dateOfPublication: v.dateOfPublication,
        copies: v.copies,
        ancestor: v.ancestor,
        owner: v.own,
        publisher: v.publisher,
        id: v.id,
      };
      // try {
      //   const res = await getDetails(v.bookID);
      //   this.infoObj = {
      //     title: res.title,
      //     publisher: res.publisher,
      //     dateOfPublication: res.dateOfPublication,
      //     copies: res.copies,
      //     ancestor: res.ancestor,
      //     owner: res.owner,
      //     publisher: res.publisher,
      //     id: res.id,
      //   };
      this.infoShow = true;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    onShowLeft() {
      if (this.isShowLeft) {
        this.isShowLeft = !this.isShowLeft;
        this.$refs.left.style.padding = 0;
      } else {
        this.isShowLeft = !this.isShowLeft;
        this.$refs.left.style.padding = "10px 20px 10px 10px";
      }
    },
    async getDetails(id) {
      this.paginationShow = false;
      if (this.bookId && this.bookId === id) {
        this.bookId = "";
        this.details = this.oldMigrateList;
        this.migrateList = this.oldMigrateList;
        this.bmapInit();

        this.paginationShow = true;
        return;
      }
      this.bookId = id;
      try {
        const res = await getDetails(id);
        this.details = res.personalMigrateDetails;
        this.migrateList = res.personalMigrateDetails;
        this.bmapInit();
      } catch (error) {
        console.log(error);
      }
    },
    showPanel(val) {
      document.getElementById(val).click();
    },
    onload() {
      // console.log("onload");
      this.$nextTick(() => {
        if (this.loadingInstance) {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        }
      });
    },
    onerror() {
      this.$nextTick(() => {
        if (this.loadingInstance) {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        }
      });
    },
    async Before() {
      this.scale = 1;
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 0,
        });
        // console.log(res, 'res')
        this.pictureFullName = res.pictureFullName;
        this.genealogyOCRInfoID = res.genealogyOCRInfoID
          .replace("；", ";")
          .split(";")[0];
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
        this.loadingInstance.close();
      }
    },
    async After() {
      this.scale = 1;

      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 1,
        });
        this.genealogyOCRInfoID = res.genealogyOCRInfoID
          .replace("；", ";")
          .split(";")[0];
        this.pictureFullName = res.pictureFullName;
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
        this.loadingInstance.close();
      }
    },

    onClose() {
      this.dialogTableVisible = false;
      // this.pictureFullName = "";
    },
    // 查看原图
    viewPic(name, id) {
      this.genealogyOCRInfoID = id.replace("；", ";").split(";")[0];
      this.pictureFullName = name;
      this.dialogTableVisible = true;
    },
    // 分页
    onCurrentChange(e) {
      this.PageIndex = e;
      this.allPointsList = {};
      this.migrationPointsList = [];
      this.singleDisplay = [];
      this.onSearch();
    },
    handleSizeChange(e) {
      this.PageSize = e;
      this.allPointsList = {};
      this.migrationPointsList = [];
      this.singleDisplay = [];
      this.onSearch();
    },
    // 点击名称
    handleCurrentChange(val) {
      if (this.currentRow && this.currentRow.id === val.id) {
        this.currentRow = {};
        this.singleDisplay = [];
        this.details = this.migrateList;
        this.bmapInit();
      } else {
        this.currentRow = val;
        this.details = [val];
        // this.singleDisplay = val.documentGenealogyMigrateDtos;
        this.migrationPointsList = val.retrunMigrates ? val.retrunMigrates : [];
        this.allPointsList = val.returnLocations ? val.returnLocations : [];
        this.migrationPointsList = Object.freeze(this.migrationPointsList);
        this.allPointsList = Object.freeze(this.allPointsList);
        this.$refs.bmap.init();
      }
    },
    handleSearch() {
      this.bookId = "";
      this.currentRow = {};
      this.PageIndex = 1;
      this.PageSize = 10;
      this.onSearch();
    },
    // 查询数据
    async onSearch() {
      let loading = Loading.service({
        fullscreen: true,
        lock: true,
        text: "Loading",
        target: ".search-result",
      });
      let BeginPlace = "";
      this.allPointsList = {};
      this.migrationPointsList = [];
      this.singleDisplay = [];
      let EndPlace = "";
      // if (this.areaName && this.areaName.includes("/")) {
      //   BeginPlace = this.areaName.replace(/\//g, "");
      // } else {
      BeginPlace = this.areaName;
      // }
      // if (this.areaName2) {
      //   EndPlace = this.areaName2.replace(/\//g, "");
      // } else {
      EndPlace = this.areaName2;
      // }

      const searchQuery = {
        PageIndex: this.PageIndex,
        PageSize: this.PageSize,
        Surname: this.Surname,
        BeginYear: this.BeginYear || 0,
        EndYear: this.EndYear || 0,
        RelatedPersons: this.RelatedPersons,
        BeginPlace,
        EndPlace,
        MigrateText: this.MigrateText,
        MigrateDynasty: this.MigrateDynasty,
      };
      try {
        const res = await getMigrate(searchQuery);
        this.migrateList = res.items;
        this.details = res.items;
        this.total = res.totalCount;
        this.oldMigrateList = Object.freeze(res.items);
        this.paginationShow = true;
        this.bmapInit();
        if (this.total == 0) {
          this.$message.info("暂无数据");
        }
      } catch (error) {
      } finally {
        loading.close();
      }
    },
    bmapInit() {
      this.migrationPointsList = [];
      this.allPointsList = {};
      this.migrateList.forEach((v) => {
        if (v.retrunMigrates) {
          this.migrationPointsList.push(...v.retrunMigrates);
        }
        this.allPointsList = Object.assign(
          this.allPointsList,
          v.returnLocations
        );
      });
      this.migrationPointsList = Object.freeze(this.migrationPointsList);
      this.allPointsList = Object.freeze(this.allPointsList);
      this.$refs.bmap.init("all");
    },
    // 省
    async getProvincialList() {
      const res = await getProvincialList();
      res.forEach((item, index) => {
        item.index = [index];
      });
      this.CountryList = res;
    },
    // 市
    async getCityList(code, dataindex) {
      const res = await getCityList(code);
      res.forEach((item, index) => {
        item.index = dataindex.concat([index]);
      });
      this.$set(this.CountryList[dataindex[0]], "children", res);
    },
    // 县
    async getDistrictList(code, dataindex) {
      const res = await getDistrictList(code);
      res.forEach((item, index) => {
        item.index = dataindex.concat([index]);
      });
      this.$set(
        this.CountryList[dataindex[0]].children[dataindex[1]],
        "children",
        res
      );
    },
    // 乡
    async getTownList(code, dataindex) {
      const res = await getTownList(code);
      res.forEach((item, index) => {
        item.index = dataindex.concat([index]);
      });
      this.$set(
        this.CountryList[dataindex[0]].children[dataindex[1]].children[
          dataindex[2]
        ],
        "children",
        res
      );
    },
    // 村
    async getVillageList(code, dataindex) {
      const res = await getVillageList(code);
      res.forEach((item, index) => {
        item.index = dataindex.concat([index]);
      });
      this.$set(
        this.CountryList[dataindex[0]].children[dataindex[1]].children[
          dataindex[2]
        ].children[dataindex[3]],
        "children",
        res
      );
    },
    //添加地区节点
    GetNodes(code, data) {
      if (data.children && data.children.length > 0) {
        // console.log("存在");
      } else {
        // console.log("不存在");
        this.loading = true;
        /* if (data.index.length == 1) {
                    this.getProvincialList(code, data.index);
                } else  */
        if (data.index.length == 1) {
          this.getCityList(code, data.index);
        } else if (data.index.length == 2) {
          this.getDistrictList(code, data.index);
        } else if (data.index.length == 3) {
          this.getTownList(code, data.index);
        } else if (data.index.length == 4) {
          this.getVillageList(code, data.index);
        } else {
          return false;
        }
      }
    },
    cityChange(value) {
      let val = value.toString().split(",").join("/");
      this.areaName = val;
    },
    cityChange1(value) {
      let val = value.toString().split(",").join("/");
      this.areaName2 = val;
    },
  },
};
</script>

<style lang="scss" >
.el-dialog__body {
  padding: 10px 20px 30px;
}
.home-container {
  display: flex;
  .left {
    height: calc(100vh - 65px);
    overflow: auto;
    padding: 10px 20px 10px 10px;
    width: 45%;
    position: relative;
    transition: 0.3s ease;

    .current {
      background-color: rgb(203, 243, 241);
    }

    .img {
      width: 25px;
      height: 25px;
      right: 0;
      top: 50%;
      cursor: pointer;
      position: absolute;
      transform: rotate(180deg);
    }
    .top {
      border: 1px solid #eee;
      border-radius: 10px;
      padding: 50px 10px 5px;
      padding-top: 10px;
      .el-input__inner {
        border: 1px solid #a5b2be;
      }
      .left-one,
      .left-two,
      .left-three {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        h3 {
          margin-bottom: 10px;
        }

        .el-input,
        .el-button {
          margin-bottom: 15px;
        }
      }

      #startPointId,
      #endPointId {
        top: -55px;
        left: 0;
        height: 40px;
        opacity: 0;
      }
    }

    .search-result {
      height: calc(100vh - 365px);
      overflow: auto;
    }
  }

  .right {
    position: relative;
    background: #eee;
    // padding-left: 20px;
    width: 100%;
    height: calc(100vh - 65px);

    .zkimg {
      width: 30px;
      height: 30px;
      left: 0;
      top: 50%;
      cursor: pointer;
      position: absolute;
      z-index: 99999;
    }

    .info {
      position: absolute;
      color: #fff;
      top: 0;
      right: 5px;
      width: 410px;
      overflow: auto;
      max-height: 350px;
      background: rgba(0, 0, 0, 0.5);
      padding: 5px;
      border-radius: 10px;
      .item {
        font-size: 14px;
        width: 400px;
        line-height: 25px;
        font-weight: 700;

        .img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }

    .time-line {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
    }
    .bottom {
      position: absolute;
      bottom: 3px;
      right: 7px;
      color: #fff;
    }
  }
}
.form-class {
  .el-form-item__label {
    font-size: 15px;
    color: black;
    font-weight: 500;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
