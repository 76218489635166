import request from '@/utils/request'

export const getPersonalList = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/magrate-appservice/migrate-infos-list`,
        params
    })
}

export const getMap = url => {
    return request({
        method: 'GET',
        url
    })
}

export const getArea = params => {
    return request({
        method: 'GET',
        url: '/external/area-new',
        params
    })
}
export const getTraditionalArea = params => {
    return request({
        method: 'GET',
        url: 'external/area-new/traditional',
        params
    })
}

// 下载模板
export const downloadTemplate = () => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/magrate-appservice/downloadTemplate',
        responseType: "blob"
    })
}

// 导出
export const exportExcel = data => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/magrate-appservice/export-personal-migrate-by-excel',
        responseType: "blob",
        data
    })
}

// 导入
export const importExcel = () => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/magrate-appservice/import-personal-migrate-by-excel',
        headers: {
            "content-type": "multipart/form-data;boundary=${boundary}",
        }
    })
}

// 删除
export const deletePerson = id => {
    return request({
        method: 'DELETE',
        url: `/knowledgeshow/magrate-appservice/${id}/personal-migrate`,

    })
}

export const getSurnameList = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/magrate-appservice/migrate-by-surname-end-place-address`,
        params

    })
}

export const searchName = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/magrate-appservice/migrate-by-surname-end-place-address-search-by-surname`,
        params

    })
}




